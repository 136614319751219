body {
  background-color: rgb(28, 30, 43);
  font-family: 'Poppins', sans-serif;
  color: #000;
  overflow-x: hidden;

}
:root {
  --blue: #00B7D4;
  --white: #ffffff; 
}
h1, h2, h3, h4, h5, h6, p, span, a {
  font-family: 'Poppins', sans-serif;
}

table, tr, td, th {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}

p {
  margin-bottom: 0px;
}

/* ===================================Header=================================================================================================================================================================================================================================================== */
.SideBar_name_p {
  color: white;
  font-size: 17px;
  text-align: end;
  font-weight: bold;
}
.Header_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 12px 32px;
}
.Header_wrapper img{
  width:300px;
}
.Header_wrapper_inner_div{
  display: flex;
  align-items: center;
  justify-content: end;
}
.Heade_Power_button {
  background-color: #282F42;
  border-radius: 100px;
  color: var(--blue);
  font-size: 35px !important;
}
/* ====================================End==================================================================================================================================================================================================================================================================== */
/* =============================LoginPage======================================================================================================================================================================================================================= */
.Login-page-form {
  width: 500px;
  /* margin: auto; */
  background: rgb(40, 47, 66);
  /* box-shadow:0px 4px 33px 12px rgb(40,47,66); */
  padding: 50px;
  /* padding-top: 37px; */
  border-radius: 8px;
  text-align: center;
  margin-top: 2rem;
}


input:focus {
  background-color: transparent !important;
}

/* input:-webkit-autofill{
  background-color: transparent !important;
  color:transparent !important;
} */
.LoginPage-Main-wrapper {
  height: 100vh;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  /* background-color: white; */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root {
  width: 95%;
  margin: auto;
}

.Login-page-form-logo-image {
  width: auto;
  height:50px
}

.Loginpage-main-form-inner-div {
  background-color: rgb(28, 30, 43) !important;
  margin: auto;
  width:100%;
  border-radius: 8px;
}

div#demo-simple-select {
  /* border: none !important; */
  border-bottom: 0.5px solid #BCC4DF !important;
  padding: 0px;
  color: #BCC4DF;
}

div#demo-simple-select_orderList {
  /* border: none !important; */
  border-bottom: 0.5px solid #BCC4DF !important;
  padding: 7px;
  /* padding-left:0px; */
  color: rgba(189, 196, 224, 0.5) !important;
  border-radius: 0px !important;
}

div#demo-simple-select_two {
  border-bottom: 0.5px solid #BCC4DF !important;
  color: rgb(189, 196, 224) !important;
}

.INdividual_fleetPage_assignrobot_select label {
  color: white !important;
}

.INdividual_fleetPage_assignrobot_select svg {
  color: white;
}

.INdividual_fleetPage_assignrobot_select fieldset {
  border-color: white !important;
}

.AddFleet_form_field_wrapper>div>div svg {
  color: rgb(151, 159, 184);
}

.AddFleet_form_field_wrapper fieldset {
  border: none !important;
}

.Loginpage-main-form-inner-div>div>div::before {
  border-bottom:none !important;

}

.Loginpage-main-form-inner-div>div>div::after {
  border-bottom: none !important;
}

.Login_page_input_wholeLabel_wrapper {
  margin-bottom: 1rem;
}

.Login_page_input_wholeLabel_wrapper label {
  font-family: 'Poppins', sans-serif;
}

.Login-form-p {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: rgb(1, 115, 212);
  font-weight: 600;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.Login-page-form h6 {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-size: 17px;
  margin-top: 0px !important;
}

.Loginpage\=-main-form {
  /* margin: 24px; */
  margin-right: 0px;
  margin-left: 0px;
  /* margin-bottom: 13px; */
  /* margin-top: 46px; */
}

.Loginpage\=-main-form input {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: rgb(189, 196, 224) !important;
  letter-spacing: 0px !important;
  padding: 12px;
  background: none !important;
  font-family: 'Poppins', sans-serif;
}

.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15.9px;
  font-family: 'Poppins', sans-serif;
  color: rgb(189, 196, 224);
  font-weight: 500;
}

.Loginpage-btn-wrapper-btn {
  color: var(--white) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: var(--blue) !important;
  padding: 13px 70px !important;
  border-radius: 8px !important;
  /* width: 21% !important; */
  border: none;
  font-weight: 700;

}

.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error {
  font-weight: 400;
  font-size: 10px;
  padding-left: 39px;
}

.Login-page-form .css-dmmspl-MuiFormGroup-root {
  padding-left: 15px
}

img.Logout-image {
  width: 100%;
  margin-left: 2px;
}

.Login-page-remember-checkbox {
  width: 25px;
  height: 25px;
}

.Login-page-remember-checkbox-span {
  margin: 10px;
  color: rgb(189, 196, 224);
}

.Loginpage\=-main-form input:-webkit-autofill {
  background: none !important;
}

.Loginpage\=-main-form input:-webkit-autofill,
.Loginpage\=-main-form input:-webkit-autofill:hover,
.Loginpage\=-main-form input:-webkit-autofill:focus,
.Loginpage\=-main-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(28, 30, 43) inset !important;
  -webkit-text-fill-color: #fff !important;
   border-radius: 8px !important;
}

.AddFleet_form_field_wrapper input:-webkit-autofill {
  background: none !important;
}

.AddFleet_form_field_wrapper input:-webkit-autofill, .AddFleet_form_field_wrapper input,
.AddFleet_form_field_wrapper input:-webkit-autofill:hover,
.AddFleet_form_field_wrapper input:-webkit-autofill:focus,
.AddFleet_form_field_wrapper input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(28, 30, 43) inset !important;
  -webkit-text-fill-color: rgb(189, 196, 224) !important;
}

.AddFleet_form_field_wrapper input {
  padding-left: 10px;
  padding: 10px;
}

.Loginpage-btn-wrapper {
  margin-top: 2rem;
}

.Login_page_forgot_password_div_wrapper p {
  text-align: end;
  color: rgb(189, 196, 224);
  width: 100%;
  font-size: 13px;
  margin-top: 0px;
}

/* =================================End======================================================================================================================================================================================================================================================================= */
/* ================================DashboardPage============================================================================================================================================================================================================================================= */

/* .Dashboard_page_Whole_content_wrapper_main {
  display: flex;
  height: 100%;
} */
.Dashboard_page_wrapper{
  padding:20px 30px;
}
.Dashboard_page_Map_wrapper_Main_whole img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}
.Dashboard_page_fleet_heading{
  font-size:29px  !important;
  color: var(--blue);
  font-weight: bold;
  text-align: left !important;
  padding-bottom:15px;

}
.header_h4{
  
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  margin-top:0px;
}
.yes-red-active {
  background: linear-gradient(to right, var(--blue), var(--blue)) !important;
  color: #ffffff !important;
}
.color-white{
  color:white !important;
  border:1px solid white !important;
}
.Cancel-order-Modal-Mainwrapper p {
  margin-bottom: 20px;
  padding-left: 14px;
  color: white;
  text-align: center;
}
.Modal-button-Main-btn {
  border-radius: 8px !important;
  padding: 6px 41px !important;
  font-weight: 600 !important;
  margin: 10px !important;
  margin-top: 0px !important;
  background: transparent;
}
.Modal-button-Main-wrapper {
  margin-top: 22px;
  text-align: center;
}
.Dashboard_page_viewstream_button button{
  background-color: #00B7D4;
  color: white;
  font-weight: 600;
  border: none;
  width: 100%;
  padding: 10px 21px;
  border-radius: 0px 0px 8px 8px;
  font-size: 18px;
}
.Dashboard_page_side_bar {
  width: 100%;
  background-color: #282F42;
  color: #BCC4DF !important;
  text-decoration: none !important;
  border-radius: 8px !important;
}

.Dashboard_page_side_bar_close {
  background-color: #282F42;
  color: #BCC4DF !important;
  width: 100%;
  list-style: none;
  padding-left: 0px !important;
}
.Header_arrow{
margin-right:10px;
margin-bottom:0px !important;
font-size:35px !important;
cursor: pointer;
}
.Dashboard_page_side_bar_close a li span img {
  width: 65% !important;
}

.Dashboard_page_side_bar {
  list-style: none;
  padding-left: 0px !important;
}

.Dashboard_side_bar_links {
  text-decoration: none !important;
  color: #BCC4DF;
}

.Dashboard_side_bar_links:hover {
  color: white !important;
}

.Dashboard_page_right_side {
  width: 85%;
  padding: 65px;
  margin-top: 3rem;
}

.Dashboard_SideBar_icon {
  /* filter: invert(100%) sepia(30%) saturate(4430%) hue-rotate(177deg) brightness(95%) contrast(83%) !important; */
  width: 25%;
  margin-right: 5px;
}

.Dashboard_SideBar_icon_one {
  width: auto;
  height: 35px;
}

.Dashboard_page_side_bar li {
  padding: 15px 20px;
  /* padding-left: 46px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 15px;
  border-left: 5px solid transparent;
}

.Dashboard_page_side_bar li span {
  /* display: none; */
  width: auto !important;
}

.Dashboard_page_side_bar li span img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.Dashboard_page_side_bar_close li {
  padding: 20px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  text-align: center;
}

.Dashboard_page_side_bar_close li p {
  display: none;
}

.Dashboard_page_side_bar_close li img {
  width: 55%;
}
.col-md-10{
  padding-right: 19px;
}

.Dashboard_side_bar_wrapper_main_long {
  /* width:15%; */
  width: 270px;
  background-color: #282F42;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 17px;
  top: 19px;
  border-radius: 8px !important;
}

/* .margin_left {
  margin-left: 15%;
} */

.margin_left_close {
  margin-left: 6%;
  width: 94% !important;
}

.Dashboard_side_bar_wrapper_main_short {
  width: 6%;
  background-color: #282F42;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* position: fixed; */
  top: 11%;
  left: 0%;
}

.SideBar_active {
  background-color: rgb(28, 30, 43);
  color: white;
  /* font-weight: 700; */
  letter-spacing: 0px;
  /* font-size: 18px; */
  border-left: 5px solid var(--blue) !important;
}
p.Dashboard_side_bar_P {
  font-weight: bold;
  color:var(--white);
}
.Dashboard_SideBar_icon_one {
  /* width:30px;
  height:30px; */
}

.Dashboard_page_rightSide_content_heading h4, .Dashboard_page_rightSide_content_heading span {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 18px;
  color: var(--blue);
  margin-bottom: 0px;
}
/* .Dashboard_page_rightSide_content_heading input{
  border:1px solid var(--blue);
} */
.Dashboard_page_rightSide_content_heading span {
  font-size: 20px;
  color:var(--white) !important;
  font-weight: bold;
}
span.FleetView_page_Heading_fleetName{
  color:var(--blue) !important;
}
.Dashboard_page_rightSide_content_heading {
  background-color: #282F42;
  margin: 20px 0;
  border-radius: 8px;
  padding: 8px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 64px;
  color: #FFF;
}
.Dashboard_page_rightSide_content_heading_formgroup{
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: end !important;
}
.Dashboard_page_rightSide_content_heading_formgroup > label> span>span:nth-child(2){
  background-color: var(--blue) !important;
  opacity: 1 !important;
}
/* .Dashboard_page_rightSide_content_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:20px;
  position: fixed;
  top: 10px;
  background-color: #282F42;
  width: 80.3%;
  padding: 18.5px;
  z-index: 9999999999 !important;
  border-radius:5px;
  margin-left:37px
} */
.PaginationWrapper ul li button {
  color: white !important;
}

.PaginationWrapper ul {
  justify-content: end;
}

.Dashboard_page_rightSide_content_heading_span {
  padding-right: 20px;
  padding-top: 0px;
}

.switchcase-active {
  color: #BCC4DF !important;
  font-weight: 300 !important;
}

.menu-bar-btn {
  background-color: transparent;
  color: white;
  border: none;
  text-align: center;
  margin: 20px;
}

.Dasboard_page_individual_fleet_box_wrapper {
  background-color: #282F42;
  color: white;
  padding: 23px;
  border-radius: 8px;
  /* margin: 23px;
  width:310px; */
  cursor: pointer;
  /* margin-left:0px;
  margin-top:0px; */
}

.Dasboard_page_individual_robot_box_wrapper {
  background-color: #282F42;
  color: white;
  /* padding: 23px; */
  border-radius: 8px;
  margin-top: 20px;
  /* margin: 23px; */
  /* width:33.333%; */
  cursor: pointer;
  /* margin-left:0px; */
}
.Dasboard_page_individual_robot_box_1 {
  background-color: #25384A;
  border-radius: 8px;
  padding:14px;
}
.Dasboard_page_individual_robot_box_2{
  padding:14px;
  }
.Dashboard_page_fleets_list_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.Dashboard_page_fleets_list_wrapper_two {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.Dasboard_page_individual_fleet_box_inner_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
div#map-container-id{
  border-radius: 8px;
}
.SelectFleetPage-inner-li-div-robot-id span {
  display: block;
  opacity: 0.7;
}

.Dashboard_page_individual_fleet_Heading_Green_h4::after {
  content: '';
  background-color: #88F976;
  width: 20%;
  height: 3px;
  position: absolute;
  left: 40%;
  top: 31px;
}

.Dashboard_page_individual_fleet_Heading_red_h4::after {
  content: '';
  background-color: #FF5757;
  width: 20%;
  height: 3px;
  position: absolute;
  left: 40%;
  top: 31px;
}

.Dasboard_page_individual_fleet_box_inner_div p {
  color: #BCC4DF;
}

.Map-Main-Whole-wrapper {
  text-align: center;
}

.FleetPage_map_tab_heading_wrapper {
  padding: 23px 31px;
}

.FleetPage_map_tab_heading_wrapper h3 {
  font-size: 19px;
}

.Dasboard_page_individual_fleet_box_inner_div p:last-child {
  color: #BCC4DF;
  font-weight: 600;
}

.Dashboard_page_robotStatusClass {
  font-size: 13px;
}

.Dasboard_page_individual_robot_h4 {
  font-size: 15px;
  margin-bottom: 2px;
}

.owl-theme .owl-dots {
  display: none !important;
}

.Dashboard_page_fleets_list_wrapper .owl-nav.disabled {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  top: 20%;
  position: absolute;
}

.Dashboard_page_fleets_list_wrapper button.owl-prev, .Dashboard_page_fleets_list_wrapper_two button.owl-prev {
  margin-left: -2rem !important;
  z-index: 999999 !important;
}

.Dashboard_page_fleets_list_wrapper button.owl-next, .Dashboard_page_fleets_list_wrapper_two button.owl-next {
  margin-right: -2rem !important;
}

button.owl-prev:hover {
  background: transparent !important;
}

button.owl-next:hover {
  background: transparent !important;
}

.Dashboard_page_fleets_list_wrapper_two .owl-nav.disabled {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  top: -4%;
  position: absolute;
}

.Dashboard_page_fleets_list_wrapper span, .Dashboard_page_fleets_list_wrapper_two span {
  font-size: 100px;
  color: #969393;
  font-weight: 200;
}

.Dashboard_page_fleets_list_wrapper span:hover, .Dashboard_page_fleets_list_wrapper_two span:hover {
  background-color: transparent;
}

.red-dot {
  background-color: #FF6767;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 19px;
  left: 106px;
}

.green-dot {
  background-color: #88F976;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 19px;
  left: 106px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.d-flex-center.Dasboard_page_individual_robot_box_2{
  position: relative !important;
}
.yellow-dot {
  background-color: #FFB800;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes dotanimation {
  0% {
    opacity: 0
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.Dashboard_page_robot_div_icons {
  display: flex;
  justify-content: space-around;
  /* margin-top: 15px; */
  font-size: 14px;
  align-items: center;
}

.Dashboard_page_robot_div_icons img {
  margin-right: 5px;
  margin-left: 5px;
}

.Dashboard_page_individual_robot_robotstatus_div {
  text-align: center;
  /* margin-top: 11px; */
  font-size: 13px;
}

.Dashboard_page_Robot_Card_heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 18px;
  color: var(--blue);
  margin-bottom: 0;
}

.Dashboard_page_robot_card_heading_wrapper {
  background-color: #282F42;
  padding: 15px 30px;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  min-height: 64px;
  /* color: var(--blue); */
}

.Dashboard_page_Robot_Card_heading_two {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: white;
  font-size: 26px;
  margin: 25px;
}

.Dashboard_page_Robot_Card_heading span {
  font-size: 13px;
}

.owl-carousel .owl-item img {
  width: 10% !important;
}

.tools {
  text-align: end;
  position: absolute;
  z-index: 999999;
  right: 20px;
}

.tools button {
  background: #3D4665 !important;
  font-weight: 600;
  margin: 5px;
  color: #BCC4DF;
  border: none;
  padding: 2px 12px;
  border-radius: 8px;
}

.Dashboard-content-div-one-left-side-h3 {
  color: white;
  text-align: left !important;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 25px;
  margin-bottom: 0px;
}

/* ======================================End========================================================================================================================================================================================================================================================== */
/* ======================================Video Page========================================================================================================================================================================================================================================================== */
.Video_page_wrapper{
 display: grid;
 grid-template-columns: 48% 49%;
 grid-gap: 20px !important;
}
.Dashboard_page_content{
  padding: 20px 30px;
}
.Video_stream_iframe{
  width:100%;
  height:300px;
  border-radius: 8px;
}
.Video_page_wrapper_div{
  /* margin:10px; */
  width:100%;
}
.Video_page_wrapper_p {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
.Dashboard_page_viewstream_button img{
  margin-right:10px;
}

/* ======================================End========================================================================================================================================================================================================================================================== */